import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UniversalApiCallsService } from 'src/app/service/universal/universal-api-calls.service';
import { sharedState } from '@citizens/mfe-shared-state';

export interface Application {
  name: string;
  displayName: string;
  oudAppName?: string;
  isRegistered?: boolean;
  isTradeMarked?: boolean;
  order?: number;
  homeURL?: string;
  learnMoreURL?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  appLoginJson?: AppLoginJson;
  linkText?: string;
  description?: string;
  src?: string;
}

export interface AppLoginJson {
  transmitJourney?: string;
  samlActionUrl?: string;
  relayState?: string;
  logoutUrl?: string;
  mobileLogoutUrl?: string;
}

export interface SamlJson {
  samlActionUrl?: string;
  relayState?: string;
}
@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  public gateway = environment.univ_treasuryLogin;
  appInfo = new Map();
  apps = new Map();
  mappingLookup = new Map();
  isCurrentRoute = false;
  configApplications: Application[];
  configApps: any;
  nonOUDAppsList: any;
  oudAppsList: any;
  shortNameMap: { [displayName: string]: string } = {};
  private deepLinkDataCache = new Map<string, any>();

  constructor(
    private readonly universalApiCallsService: UniversalApiCallsService
  ) { }

  init() {
    this.universalApiCallsService.getAppsData().subscribe(data => {
      this.configApps = data;
      this.nonOUDAppsList = data.filter(app => !app.isOUDApp).map(data => data.applicationName);
      this.oudAppsList = data.filter(app => app.isOUDApp).map(data => data.applicationName);
      data.forEach(val => {
        this.apps.set(val.name, val);
        this.apps.forEach((item) => {
          this.mappingLookup.set(item.mappingName, item);
        })
        this.shortNameMap[val.displayName] = val.applicationName
      });
      sharedState.setApplicationDataSub("appMappingLookup", this.mappingLookup);
    })

  }

  initDashboard() {
    const isSMBUser = sessionStorage.getItem("isSMBUser") === "true";
    if (isSMBUser) {
      this.gateway = environment.univ_smbUserLogin;
    }

    if (this.gateway !== undefined) {
      const apps: Application[] = this.gateway;
      this.setGatewayData(apps);
    }
  }

  getNonOUDAppsList() {
    return this.nonOUDAppsList;
  }

  getOUDAppsList() {
    return this.oudAppsList;
  }

  getConfigAppsData(selectedAppName: any) {
    if (!selectedAppName) return undefined;
    const appKey = selectedAppName.trim().toLowerCase();
    return this.configApps.find((val: any) => 
      val.name.toLowerCase() === appKey || 
      val.mappingName.toLowerCase() === appKey);
  }

  getShortAppName(selectedAppName: any) {
    return this.shortNameMap[selectedAppName] || '';
  }

  isApp(appName: string) {
    return Array.from(this.apps.values()).some(app => app.mappingName === appName);
  }

  getHomeUrl(appName: string): string {
    if (this.appInfo.has(appName)) {
      const app: Application = this.appInfo.get(appName);
      return app.homeURL;
    }
    return undefined;
  }

  getLearnMoreUrl(appName: string): string {
    if (this.appInfo.has(appName)) {
      const app: Application = this.appInfo.get(appName);
      return app.learnMoreURL;
    }
    return undefined;
  }

  getGatewayData(): object {
    return this.appInfo;
  }

  setGatewayData(apps: Application[]) {
    apps.forEach(data => {
      this.appInfo.set(data.name, {
        name: data.name,
        displayName: data.displayName,
        oudAppName: data.oudAppName,
        isRegistered: data.isRegistered,
        order: data.order,
        homeURL: data.homeURL,
        learnMoreURL: data.learnMoreURL,
        isDisabled: data.isDisabled,
        linkText: data.linkText,
        description: data.description,
        imgSrc: data.src
      });
    });
  }

  private findInMappingLookup<T>(appName: string, keyPath: string): any {
    appName = appName.toLowerCase();
    for (const [key, value] of this.mappingLookup.entries()) {
      if (key.toLowerCase() === appName) {
        return keyPath.split('.').reduce((acc, key) => acc[key], value)
      }
    }
    return undefined;
  }

  getTransmitJourney(appName: string): string | undefined {
    return this.findInMappingLookup(appName, 'appLoginJson.transmitJourney')
  }

  getDisplayName(appName: string): string | undefined {
    return this.findInMappingLookup(appName, 'displayName')
  }

  getApplicationName(appName: any) {
    return this.findInMappingLookup(appName, 'applicationName')
  }

  getNonOUDApps(appName: string): boolean {
    this.universalApiCallsService.getAppsData().subscribe(data => {
      data.forEach(val => {
        if (val.name === appName)
          return true;
      })
    })
    return false;
  }

  getOudAppName(appName: string): string {
    return this.mappingLookup.get(appName)?.oudAppName || undefined;
  }

  getIsRegistered(appName: string): boolean {
    return this.findInMappingLookup(appName, 'isRegistered');
  }

  getIsTradeMarked(appName: string): boolean {
    return this.findInMappingLookup(appName, 'isTradeMarked');
  }

  getSamlInfo(appName: string): object {
    const app = this.mappingLookup.get(appName)
    return app ? { samlActionUrl: app.appLoginJson?.samlActionUrl, relayState: app.appLoginJson?.relayState } : undefined;
  }

  getLogoutInfo(appName: string): object {
    const app = this.mappingLookup.get(appName)
    return app ? { logoutUrl: app.appLoginJson?.logoutUrl, mobileLogoutUrl: app.appLoginJson?.mobileLogoutUrl } : undefined;
  }

  getAppInfo(appName: string): object {
    const app = this.mappingLookup.get(appName)
    return app ? {
      displayName: app?.displayName,
      oudAppName: app?.oudAppName,
      isRegistered: app?.isRegistered,
      isActive: app?.isActive
    } : undefined
  }

  textEllipis(value) {
    if (value.length > 15) {
      return value.substring(0, 12) + '...';
    }
    return value;
  }

  async getDeepLinkURL(userId: any, compId: any, appName: any, notification: any, appData: any) {
    const hintValue = userId + (compId ? ('_' + compId) : '') + (appData.name ? ('_' + appData.name) : '');
    const specialConfig = appData['specialConfiguration'];
    const deeplinkUrl = (environment?.isQA && sessionStorage.getItem('preprod')) ? 
      appData['deeplinkUrlpreProd'] : specialConfig['deeplinkUrl'];
    const parnerSpId = this.extractPartnerSpId(deeplinkUrl);
    let resourceEndPoint = await this.getResourceEndPoint(appName.toLowerCase(), notification);
    return deeplinkUrl
      .replace('<<SSOID>>', hintValue.toUpperCase())
      .replace('<<BASE_URL>>', parnerSpId)
      .replace('<<RESOURCE_ENDPOINT>>', resourceEndPoint);
  }

  extractPartnerSpId(deeplinkUrl: string) {
    const urlObj = new URL(deeplinkUrl);
    const partnerSpId = new URLSearchParams(urlObj.search).get("PartnerSpId");
    return partnerSpId ? new URL(partnerSpId).origin : null;
  }

  getResourceEndPoint(appName: string, notification: any): Promise<any> {
    let type = notification ? notification?.type : 'DEFAULT';
    return new Promise((resolve, reject) => {
      if (this.deepLinkDataCache.has(appName)) {
        const data = this.deepLinkDataCache.get(appName);
        const value = data.find((val: any) => val.type === type);
        resolve(value ? value.resourceEndpoint : undefined);
      } else {
        this.universalApiCallsService.getDeeplinkFromCDN(appName).subscribe((data: any[]) => {
          this.deepLinkDataCache.set(appName, data);
          const value = data.find((val: any) => val.type === type);
          resolve(value ? value.resourceEndpoint : undefined);
        }, (error: any) => {
          reject(error);
        });
      }
    });
  }
}
