import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { UniversalSessionService } from 'src/app/core/service/universalSession.service';

@Injectable({
  providedIn: 'root'
})
export class UniversalApiCallsService {

  // config
  public appDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public appData$: Observable<any> = this.appDataSubject.asObservable();

  public configData: any;
  public currentSectionSubject = new BehaviorSubject<string>('');
  currentSection$ = this.currentSectionSubject.asObservable();

  constructor(private https: HttpClient, private universalSession: UniversalSessionService) { }

  setCurrentSection(section: string) {
    this.currentSectionSubject.next(section)
  }

  public removeCreds(universalId, legacy_uid): Observable<any> {

    const url = environment.univ_removeCreds;

    const body = {
      "universalId": universalId,
      "legacyId": legacy_uid
    };

    return this.https.post(url, body);
  }

  public saveUserProfile() {
    const url = environment.univ_saveCreds;
    return this.https.post<any>(url, this.universalSession.enrollApiBody);
  }

  public saveNonOudUserProfile() {
    const url = environment.univ_nonOudSaveCreds + this.universalSession.getUniversal();
    return this.https.post<any>(url, this.universalSession.enrollNonOudApiBody);
  }

  public deleteCreds(universalId) {
    const url = environment.univ_deleteUserProfile + `/${universalId}`;
    return this.https.post<any>(url, {});
  }

  // for butler
  public getContactId(universalId: string, persistentId: string) {
    const body = {
      "universalId": universalId,
      "persistentId": persistentId,
      "applicationName": "Digital Butler"
    }

    const url = environment.univ_getButlerContactID;
    return this.https.post<any>(url, body);
  }

  // config
  getAppsData(): Observable<any[]> {
    const url = environment.univ_apps_url;
    return this.https.get<any[]>(url);
  }

  updateSelectedAppData(selectedAppName: any): void {
    this.getAppsData().subscribe((data: any[]) => {
      const selectedAppData = data.find((val: any) => val.name === selectedAppName);
      if (selectedAppData) {
        this.appDataSubject.next(selectedAppData);
        this.configData = selectedAppData;
      }
    })
  }

  // relay state mail
  sendRelayStateEmail(relayState: string, universalId: string, userAgent: any) {
    const body = {
      "universalId" : universalId,
      "relayState" : relayState,
      "userAgent" : userAgent,
      "mail": environment.univ_relayStateEmailID
    }
    const url = environment.univ_relayStateEmail;
    return this.https.post<any>(url, body);
  }

  // user admin check
  checkAdmin(legacy_id: string) {
    const url = environment.univ_getAdminData + '/' + legacy_id;
    return this.https.get<any>(url);
  }

  // cdn call
  getCommercialServicesFromCDN() {
    const url = environment.commercialServicesCDNUrl;
    return this.https.get<any>(url);
  }

  getFooterLinksFromCDN() {
    const url = environment.footerLinksCDNUrl;
    return this.https.get<any>(url);
  }

  getDeeplinkFromCDN(appName: string) {
    const url = environment.deeplinkCDNUrl + appName + '-deeplink.json';
    return this.https.get<any[]>(url);
  }

  public getConsentData(): Observable<any> {
    const manageConsentUrl = environment.univ_manage_consent;
    const httpOptions = {
      headers: new HttpHeaders({
        'universalId': this.universalSession.getUniversal()
      })
    };
    return this.https.get<any>(manageConsentUrl, httpOptions);
  }

  public removeConsent(consentId: number): Observable<any> {
    const revokeUrl = environment.univ_manage_consent_revoke + '?consentId=' + consentId;
    const httpOptions = {
      headers: new HttpHeaders({
        'universalId': this.universalSession.getUniversal()
      })
    };
    return this.https.get<any>(revokeUrl, httpOptions);
  }

  public getImageFromCDN(vendorId) {
    const cdnUrl = environment.cdnUrl + vendorId + '.png';
    return this.https.get(cdnUrl, { responseType: 'blob' });
  }

  public getConsentManagementFlagFromCDN() {
    const url = environment.consentManagementFlagCDN;
    return this.https.get<any>(url);
  }

  public billingAccountList(compIds): Observable<any> {
    const url = `${environment.acc_verif_billingAccountDetails}${compIds.join(',')}`;
    return this.https.get<any>(url);
}
}
