import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { User } from '../../models/user';
import { UserCredentials } from '../../models/user-credentials';
import { catchError, map, shareReplay, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, Subject, forkJoin, of } from 'rxjs';
import { Router } from '@angular/router';
import { Credentials } from '../../models/credentials';
import { ApplicationService } from '../../core/service/application.service';
import { UniversalSessionService } from '../../core/service/universalSession.service';
import { TabService } from '../../core/service/tab.service';
import { UniversalApiCallsService } from '../universal/universal-api-calls.service';
import { sharedState } from '@citizens/mfe-shared-state';

export interface PageLayout {
  pageName: string;
  user: User;
  layout?: (LayoutEntity)[] | null;
}

export interface LayoutEntity {
  column: number;
  order: number;
  widgetName: string;
  widgetJson: WidgetJson;
  data?: object | null;
}

export interface WidgetJson {
  title: string;
  isOpen: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService implements OnDestroy {
  emailId: string;
  showSplash: boolean = true;
  classicView: boolean = false;
  // fraudPreventionSplash: boolean = false;
  public legacyId = new BehaviorSubject<string>('');
  private fraudPreventionSplashSubject = new BehaviorSubject<string>('');
  public fraudPreventionSplash$ = this.fraudPreventionSplashSubject.asObservable();
  private erpSplashSubject = new BehaviorSubject<string>('');
  public erpSplash$ = this.erpSplashSubject.asObservable();
  private erpConnectInfoSubject = new BehaviorSubject<string>('');
  public erpConnectInfo$ = this.erpConnectInfoSubject.asObservable();
  private acctVerfSplashInfoSubject = new BehaviorSubject<string>('');
  public acctVerfSplashInfo$ = this.acctVerfSplashInfoSubject.asObservable();
  private newErpConnectInfoSubject = new BehaviorSubject<string>('');
  public newErpConnectInfo$ = this.newErpConnectInfoSubject.asObservable();
  private fdxApiSplashInfoSubject = new BehaviorSubject<string>('');
  public fdxApiSplashInfo$ = this.fdxApiSplashInfoSubject.asObservable();
  private erpConnectInfoPopupTrigger = new Subject<void>();
  public mergedAcknowledgement: any;
  private samlErrorCodes = ['SMSR01', 'SMSR02', 'SMSR03', 'SMSR04', 'SMSR05', 'SMSR06', 'SMSR07', 'SMSR08', 'SMSR09', 'SMSR10', 'SMSR11', 'SMSR12'];

  private landingLayout = environment.univ_desktop_landingLayout;
  private mobileLayout = environment.univ_mobile_landingLayout;
  public updateLastLoginDetailsUrl = environment.univ_updateLastLoginDetails;
  private univSession = environment.univ_session;
  private credentialsUrl: string;
  private mobileCredentialsUrl: string;
  private universalId: string;
  private credentials$: Observable<UserCredentials[]>;

  public credentialsDataSource: BehaviorSubject<any> = new BehaviorSubject(1);
  readonly credentialsData$ = this.credentialsDataSource.asObservable();

  public previousDisableState: BehaviorSubject<any> = new BehaviorSubject(1);
  readonly previousDisableStateData$ = this.previousDisableState.asObservable();

  private userCredentials: UserCredentials;
  private credentials: Credentials[];
  private userInfo: User;
  private data: [];
  private bannerDisplayPreferences = [];
  public userDisabled: boolean = false;

  private cardsPerPage: number;
  private widgetsCardsPerPage: number;
  private totalPages: number;
  private overflowWidth: string;
  private cardWidth: string;
  private pagePosition: string;

  constructor(private https: HttpClient,
    private sessionService: UniversalSessionService,
    private tabService: TabService,
    private appService: ApplicationService,
    private router: Router) {
    this.credentialsUrl = this.univSession.basePath + this.univSession.credentials;
    this.mobileCredentialsUrl = this.univSession.basePath + this.univSession.mobileCredentials;
  }



  getCredentialsDataSourceValues(): any {
    return this.credentialsDataSource.getValue();
  }

  setUserInfo(userInfo: User): void {
    this.userInfo = userInfo;
  }

  setData(data: any): void {
    this.data = data;
  }

  getData(): any {
    return this.data;
  }

  setUserCredentials(users: UserCredentials): void {
    this.userCredentials = users;
  }

  setCredentials(credentials: Credentials[]): void {
    this.credentials = credentials;
  }

  setFraudPreventionSplash(value: string) {
    this.fraudPreventionSplashSubject.next(value);
  }

  getFraudPreventionSplash() {
    return this.fraudPreventionSplashSubject.getValue();
  }

  setErpSplash(value: string) {
    this.erpSplashSubject.next(value);
  }

  getErpSplash() {
    return this.erpSplashSubject.getValue();
  }

  setErpConnectInfo(value: string) {
    this.erpConnectInfoSubject.next(value);
  }

  getErpConnectInfo() {
    return this.erpConnectInfoSubject.getValue();
  }

  triggerErpConnectInfoPopup() {
    this.erpConnectInfoPopupTrigger.next();
  }

  getErpConnectInfoPopup(): Observable<void> {
    return this.erpConnectInfoPopupTrigger.asObservable();
  }

  setAcctVerfSplash(value: string) {
    this.acctVerfSplashInfoSubject.next(value);
  }

  getAcctVerfSplash() {
    return this.acctVerfSplashInfoSubject.getValue();
  }

  setFdxApiSplash(value: string) {
    this.fdxApiSplashInfoSubject.next(value);
  }

  getFdxApiSplash() {
    return this.fdxApiSplashInfoSubject.getValue();
  }

  /**
   * GET the dashboard from the appConfigService
   */
  getDashboardConfig() {
    if (this.landingLayout && this.landingLayout['results']) {
      const isSMBUser = sessionStorage.getItem('isSMBUser') === 'true';
      const userType = sessionStorage.getItem('userType')
      const universalLoginApp = sessionStorage.getItem('universalLoginApp') || '';

      if(userType === 'privateBanking') {
        console.log('Inside Private Banking User')
        return this.landingLayout['results']['2']
      } else {
        if(isSMBUser === true) {
          console.log("SMBUSER",this.landingLayout['results'][1])
          return this.landingLayout['results'][1];
        } else if (universalLoginApp.toLowerCase() === 'tpoconnect') {
          return this.landingLayout['results'][2];
        }
          return this.landingLayout['results'][0];
      }
    }
    return undefined;
  }

  /**
   * GET the dashboard from the appConfigService
   */
  getMobileConfig() {
    if (this.mobileLayout && this.mobileLayout['results']) {
      return this.mobileLayout['results'][0];
    }
    return undefined;
  }

  getConfigError() {
    if (!this.landingLayout || !this.landingLayout['results']) {
      return 'Dashboard configuration error.';
    }
  }

  getAllCredentials(refresh?: boolean): Observable<UserCredentials[]> {
    if (!this.credentials$ || refresh) {
      this.universalId = sessionStorage.getItem('universalId');
      if (this.universalId === undefined || this.universalId === '') {
        console.log('Universal Id is not present : ', this.universalId);
      }
      let params = new HttpParams()
        .set('universalId', this.universalId);


      const isMobileApp = this.sessionService.isMobileApp();
      if (!!isMobileApp) {
        params = params.set('mobile', 'true');

        // for individual apps for mobile
        const currentMobileApp = sessionStorage.getItem('currentMobileApp');
        if (currentMobileApp && currentMobileApp.length > 0) {
          params = params.set('appNames', currentMobileApp); // for SMARTDATA or any other app
        } else if (!currentMobileApp || currentMobileApp.length === 0) {
          params = params.set('appNames', 'MMGPS,CASHFLOW'); // default for aO & Cashflow
        }
      }
      this.credentials$ = this.https.get<UserCredentials[]>(this.credentialsUrl, { params }).pipe(
        shareReplay(1));
    }
    sharedState.setApplicationDataSub("linkedCredentialInfo", sessionStorage.getItem('linkedCredentialInfo'));
    return this.credentials$;
  }

  getAllCredentialsByLogin(refresh?: boolean, authToken?: string): Observable<UserCredentials[]> {
    if (authToken && (!this.credentials$ || refresh)) {
      const code_verifier = sessionStorage.getItem('code_verifier');
      this.universalId = sessionStorage.getItem('universalId');
      if (this.universalId === undefined || this.universalId === '') {
        console.log('Universal Id is not present : ', this.universalId);
      }
      let params = new HttpParams();

      let body: any = { // regular desktop call
        universalId: this.universalId,
        authToken: authToken,
        codeVerifier: code_verifier
      }

      const isMobileApp = this.sessionService.isMobileApp();
      const currentMobileApp = sessionStorage.getItem('currentMobileApp');
      if (!!isMobileApp) {
        params = params.set('mobile', 'true');
        // for SMARTDATA or any other app
        if (currentMobileApp && currentMobileApp.length > 0) {
          body.appNames = currentMobileApp.includes(',') ? currentMobileApp.split(',') : [currentMobileApp];
        // current default behavoir for mobile
        } else if (!currentMobileApp || currentMobileApp.length === 0) {
          body.appNames = ['MMGPS', 'CASHFLOW']; // default for aO & Cashflow
        }
      }
      const url = environment.univ_pkce_authenticate;
      this.credentials$ = this.https.post<UserCredentials[]>(url, body, {
        params,
        observe: 'response' // Observe the full response, including headers
      }).pipe(
        map((response: HttpResponse<UserCredentials[]>) => {
          const headers: HttpHeaders = response.headers;
          var logid = '';
          headers.keys().forEach(key => {
            if (key.toLowerCase() == 'logid') {
              logid = headers.get(key);
              sessionStorage.setItem('logId', logid);
            }
          });
          return response.body || [];
        })
      );
    }
    return this.credentials$;
  }

  /**
   * Gets the list of credentials and the user information
   * this will also add the application information to the credentials
   * so that the credentials widget will have all the information it needs
   *
   * Using a Subject based on the assumption that lastLoginDate may need to be merged in the service
      // SMSR01 - "ERROR decoding the JWT token
      // SMSR02 - "mappinglegacyid is null"
      // SMSR03 - "Universal ID is null"
      // SMSR04 - "Exception occured, no data present for universal Id:" + universalId
      // SMSR05 - "Universal ID is null for Linked Persona"
      // SMSR06 - "User Profile ID is null"
      // SMSR07 - "Session already expired"
      // SMSR08 - "Session Id mismatch"
      // SMSR09 - "Token Code is null"
      // SMSR10 - "IdToken Code is null"
      // SMSR11 - "Error occured setting LastLogin"
      // SMSR12 - "Not a valid User"
   */
  getCredentials(refresh?: boolean): any {
    if (refresh) {
      this.credentials$ = this.getAllCredentialsByLogin(refresh);
    }
    let disabledCredentials: any = {}
    let checkCredDisabled = this.checkLegacyAccountDisabledStatus();
    let getAllCreds = this.getAllCredentials(refresh).pipe(catchError(error => of(error)));

    forkJoin([checkCredDisabled, getAllCreds]).subscribe(([credDisabledResp, userCreds]) => {
      if (!credDisabledResp.hasOwnProperty('errorCode')) {
        disabledCredentials = credDisabledResp;
        if (disabledCredentials.hasOwnProperty('universalIdDisabled')) {
          this.userDisabled = disabledCredentials['universalIdDisabled']
        }
      } else {
        console.log(credDisabledResp);
      }
      if (!userCreds.hasOwnProperty('errorCode')) {
        this.data = userCreds;
        console.warn('>>>> Credential json data = ');
        console.warn(userCreds);
        this.userCredentials = new UserCredentials(userCreds);
        const { userPreferences } = JSON.parse(userCreds.linkedCredentialsJson);
        if (userPreferences !== undefined) {
          const { isClassicLayout } = JSON.parse(userPreferences);
          this.classicView = isClassicLayout;
        } else {
          this.classicView = false;
        }
        const linkedCredentialsJson = JSON.parse(userCreds.linkedCredentialsJson);
        const acknowledgements = linkedCredentialsJson?.acknowledgements ? JSON.parse(linkedCredentialsJson.acknowledgements) : undefined;
        const fraudProtectionAcknowledgement = acknowledgements?.fraudProtectionAcknowledgement || 'notavailable';
        const erpSplashValue = acknowledgements?.erpInfo || 'notavailable';
        const erpConnectInfoValue = acknowledgements?.erpConnectInfo || 'notavailable';
        const accountVerfSplashVal = acknowledgements?.accountVerfSplash || 'notavailable';
        const fdxApiSplashVal = acknowledgements?.fdxApiSplash || 'notavailable';
        this.setErpSplash(erpSplashValue);
        this.setErpConnectInfo(erpConnectInfoValue);
        this.setAcctVerfSplash(accountVerfSplashVal);
        this.setFdxApiSplash(fdxApiSplashVal);
        // this.setFraudPreventionSplash(fraudProtectionAcknowledgement);

        this.sessionService.setSessionInfo(this.userCredentials.webSessionId, this.userCredentials.userProfileId);
        this.credentials = this.userCredentials.getCredentials();
        let checkLegacyAccountDisabled = [];
        let nonEnvironmentCredentials = [];
        this.credentials.forEach(cred => {
          if (disabledCredentials) {
            let legacy_creds = disabledCredentials[cred.accountLogin]
            if (!legacy_creds || legacy_creds instanceof String || typeof legacy_creds === 'string') {
              cred.disabled = false;
              cred.locked = false;
            } else {
              cred.disabled = legacy_creds.user_disabled;
              cred.locked = legacy_creds.user_locked;
            }
          }
          // get the application related information
          if (cred) {
            let appName = cred.applicationName;
            if (!appName) {
              appName = cred.legacyApplicationId;
            }
            let legacyAccountName = cred.accountLogin;
            legacyAccountName = this.removeLastIndex(legacyAccountName).toLowerCase();
            const appInfo = this.appService.getAppInfo(appName);
            cred.addApplicationAttributes(appInfo);
          }
        });
        const userInfo = this.userCredentials.getUserInfo();
        this.setUserInfo(userInfo);
        this.credentialsDataSource.next({
          credentials: this.credentials,
          userInfo: userInfo,
          error: undefined
        });

        if (this.credentials.length !== 0) {
          // let disabledState = [];
          forkJoin(checkLegacyAccountDisabled).pipe(catchError(err => of(err))).subscribe(res => {
            sessionStorage.setItem('previousDisableState', JSON.stringify([]));
            nonEnvironmentCredentials = nonEnvironmentCredentials.map((cred, index) => {
              // if (!environment.nonOUDApps.includes(cred.legacyApplicationId)) {
              // config
              if (!this.appService.getNonOUDAppsList()?.includes(cred.legacyApplicationId)) {
                if (
                  this.getNested(
                    res[index],
                    'data',
                    'data',
                    'json_data',
                    'user_disabled'
                  ) || this.getNested(
                    res[index],
                    'data',
                    'data',
                    'json_data',
                    'user_locked'
                  )
                ) {
                  cred.disabled = res[index].data.data.json_data.user_disabled;
                  cred.locked = res[index].data.data.json_data.user_locked;
                } else {
                  cred.disabled = false;
                  cred.locked = false;
                }
              }

              return cred;
            });
            this.userInfo = this.userCredentials.getUserInfo();
            this.setUserInfo(this.userInfo);
            this.credentials = this.credentials.map(eachCred => {

              const findCred = nonEnvironmentCredentials.find(nonEnv =>
                nonEnv.userId === eachCred.userId && nonEnv.compId === eachCred.compId && nonEnv.displayName === eachCred.displayName)
              if (findCred) {
                eachCred.disabled = findCred.disabled;
                eachCred.locked = findCred.locked;
              }

              return eachCred;
            });
            if (this.userCredentials.errorMessage == '') {
              this.credentialsDataSource.next({
                credentials: this.credentials,
                userInfo: this.userInfo,
                error: undefined
              });
            } else {
              this.credentialsDataSource.next({
                credentials: this.credentials,
                userInfo: this.userInfo,
                error: {
                  message: "Error " + this.userCredentials.errorCode + " occurred retrieving credentials.  Please try again or contact support."
                }
              });
            }
          });
        } else {
          this.userInfo = this.userCredentials.getUserInfo();
          this.setUserInfo(this.userInfo);
          this.credentialsDataSource.next({
            credentials: this.credentials,
            userInfo: this.userInfo,
            error: {
              message: "Error " + this.userCredentials.errorCode + " occurred retrieving credentials.  Please try again or contact support."
            }
          })
        }
      } else {
        console.error('Error retrieving Credentials:', userCreds);
        // TODO: refine this once it has been fully integrated
        this.credentialsDataSource.next({
          credentials: undefined,
          userInfo: undefined,
          error: {
            code: userCreds,
            description: userCreds
          }
        });
      }

    }, err => { console.log(err) })
  }

  updateCredentials(journeyName: string, updatedCredentials: any) {
    if (journeyName == 'add') {
      if (this.credentials == null) {
        this.credentials = [];
        this.credentials[0] = updatedCredentials;
      } else {
        let newres = this.credentials;
        newres[newres.length] = updatedCredentials;
      }
    } else if (journeyName == 'remove') {
      let newres = this.credentials;
      newres.forEach((element, index) => {
        if (element.accountLogin == updatedCredentials['legacyId']) {
          delete newres[index];
        }
      });
    }
    this.data['results'] = this.credentials.filter(item => item);
    sessionStorage.setItem('credData', JSON.stringify(this.data));
    this.userCredentials = new UserCredentials(this.data);
    this.credentials = this.userCredentials.getCredentials();

    this.credentials.forEach(cred => {
      // get the application related information
      if (cred && cred.applicationName) {
        const appName = cred.applicationName;
        const appInfo = this.appService.getAppInfo(appName);
        cred.addApplicationAttributes(appInfo);
      }
    });

    if (this.userCredentials.errorMessage !== '') {
      this.credentialsDataSource.next({
        credentials: this.credentials,
        userInfo: this.userInfo,
        error: undefined
      });
    } else {
      this.credentialsDataSource.next({
        credentials: this.credentials,
        userInfo: this.userInfo,
        error: {
          message: this.userCredentials.errorMessage
        }
      });
    }
  }

  isUserLoggedIn() {
    return localStorage.getItem('isLoggedIn');
  }
  updateContactInfo(contactInfo: any) {
    const updatedEmail = contactInfo['email'];

    const updatedMobile = contactInfo['mobile'];
    const updatedMobileCountryCode = contactInfo['mobile_code'];

    const updatedLandline = contactInfo['landline'];
    const updatedLandlineCountryCode = contactInfo['landline_code'];

    let updatedMobileNumber = updatedMobile;
    let updatedLandlineNumber = updatedLandline;

    if (updatedMobileCountryCode !== '1' && updatedMobile.length > 0) {
      updatedMobileNumber = '(' + updatedMobileCountryCode + ')' + updatedMobile;
    }

    if (updatedLandlineCountryCode !== '1' && updatedLandline.length > 0) {
      updatedLandlineNumber = '(' + updatedLandlineCountryCode + ')' + updatedLandline;
    }

    this.data['email'] = updatedEmail;
    this.data['homePhone'] = updatedLandlineNumber;
    this.data['mobilePhone'] = updatedMobileNumber;

    this.userCredentials = new UserCredentials(this.data);
  }

  updateLastLogin(accountLoginId) {
    // let accountLoginDT = "";
    // const oudAppName = this.appService.getOudAppName(appName);
    // if (compId === undefined || compId === null || compId === '') {
    //   accountLoginDT = userId + "_" + oudAppName;
    // } else {
    //   accountLoginDT = userId + "_" + compId + "_" + oudAppName;
    // }
    const url = this.updateLastLoginDetailsUrl + this.universalId;

    const body = {
      "accountLogin": accountLoginId,
      "lastLoginDt": ""
    };
    this.https.post<any>(url, body).subscribe(res => {
      console.log(res);
    });

  }

  checkLegacyAccountDisabledStatus(): Observable<any> {

    this.universalId = sessionStorage.getItem('universalId');
    let params = new HttpParams()
      .set('universalId', this.universalId);
    const isMobileApp = this.sessionService.isMobileApp();
    if (!!isMobileApp) {
      params = params.set('mobile', 'true');
      // for individual apps for mobile
      const currentMobileApp = sessionStorage.getItem('currentMobileApp');
      if (currentMobileApp && currentMobileApp.length > 0) {
        params = params.set('appNames', currentMobileApp); // for SMARTDATA or any other app
      } else if (!currentMobileApp || currentMobileApp.length === 0) {
        params = params.set('appNames', 'MMGPS,CASHFLOW'); // default for aO & Cashflow
      }
    }
    return this.https.get(environment.univ_checkCredentialsDisabledURL, { params }).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessage = error?.toString() || '';
        if (errorMessage.includes('Error Status: 401')) {
          this.router.navigate(['uportal/login']);
        }
        return throwError(() => error);
      })
    );
  }

  removeLastIndex(legacyAccount: string) {
    let index = legacyAccount.lastIndexOf("_");
    return legacyAccount.slice(0, index);
  }

  getNested(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj);
  }

  ngOnDestroy() {
    this.credentialsDataSource.complete();
  }

  saveRemovedBanners(name) {
    if (this.bannerDisplayPreferences.length == 0) {
      this.bannerDisplayPreferences = this.getRemovedBanners();
    }
    this.bannerDisplayPreferences.push(name);
    sessionStorage.setItem('banner_preference', JSON.stringify(this.bannerDisplayPreferences));
  }

  getRemovedBanners() {
    let bannerPreferences = JSON.parse(sessionStorage.getItem('banner_preference'));
    return bannerPreferences || [];
  }

  getUserDisabledStatus() {
    return this.userDisabled;
  }

  setUserDisabledStatus(status) {
    this.userDisabled = status;
  }

  getInsights(companyIds) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: environment.transmitToken
      })
    };
    const url = environment.univ_insights + '/' + companyIds + '/' + this.universalId;
    return this.https.get<any>(url);
  }

  initializeSlider(length, type) {
    let totalCards = length;
    this.totalPages = Math.ceil(totalCards / (type === 'insights' ? this.widgetsCardsPerPage : this.cardsPerPage));
    this.overflowWidth = `calc(${this.totalPages * 100}% + ${this.totalPages *
      10}px)`;
    this.cardWidth = `calc((${100 / this.totalPages}% - ${(type === 'insights' ? this.widgetsCardsPerPage : this.cardsPerPage) *
      10}px) / ${(type === 'insights' ? this.widgetsCardsPerPage : this.cardsPerPage)})`;

    return {
      totalPages: this.totalPages,
      overflowWidth: this.overflowWidth,
      cardWidth: this.cardWidth
    }
  }

  getCardsPerPage(number, type) {
    if (type === 'insights') {
      this.widgetsCardsPerPage = number
    } else {
      this.cardsPerPage = number;
    }
    return number;
  }

  changePage(currentPage, incrementor) {
    currentPage += incrementor;
    this.populatePagePosition(currentPage);
    return currentPage;
  }

  populatePagePosition(currentPage) {
    this.pagePosition = `calc(${-100 * (currentPage - 1)}% - ${10 *
      (currentPage - 1)}px)`;
  }

  getPagePosition() {
    return this.pagePosition;
  }

  saveClassicViewPreference(credentialsLayout) {
    const url = environment.univ_saveUserProfileJsonData;
    const body = {
      universalId: this.universalId,
      userPreferences: { isClassicLayout: credentialsLayout === 'classic' }
    };
    return this.https.post<any>(url, body).subscribe((res) => {
      console.log(res);
    }, error => {
      console.log(error);
      if (error.includes('Error Status: 200')) {
        this.classicView = credentialsLayout === 'classic';
      }
    });

  }

  saveFraudAcknowledgement(userAcknowledgementValue) {
    const url = environment.univ_saveUserProfileJsonData;
    const body = {
      universalId: this.universalId,
      acknowledgements: { fraudProtectionAcknowledgement: userAcknowledgementValue }
    };
    return this.https.post<any>(url, body).subscribe((res) => {
      console.log(res);
    }, error => {
      if (error.includes('Error Status: 200')) {
        this.setFraudPreventionSplash(userAcknowledgementValue);
      }
      console.log(error);
    });

  }

  saveErpInfo(erpValue) {
    const url = environment.univ_saveUserProfileJsonData;

    const mergedAcknowledgements = { ...this.mergedAcknowledgement, erpInfo: erpValue };

    const body = {
      universalId: this.universalId,
      acknowledgements: mergedAcknowledgements
    };

    sessionStorage.setItem('ack', JSON.stringify(mergedAcknowledgements));
    if (erpValue === 'NetSuite' || erpValue === 'Sage Intacct' || erpValue === 'Microsoft Dynamics Business Central') {
        this.triggerErpConnectInfoPopup();
      }
    this.https.post<any>(url, body).subscribe(
      (res) => {
        console.log(res);
      },
      (error) => {
        if (error.includes('Error Status: 200')) {
          this.setErpSplash(erpValue);
        }
        console.log(error);
      });
  }

  saveErpConnectInfo(erpConnectValue) {
    const url = environment.univ_saveUserProfileJsonData;
    const ack = sessionStorage.getItem('ack');
    let mergedAck;
      if (ack) {
        mergedAck = JSON.parse(ack)
      }

    const mergedAcknowledgements = { ...mergedAck, erpConnectInfo: erpConnectValue };

    const body = {
      universalId: this.universalId,
      acknowledgements: mergedAcknowledgements
    };

    this.https.post<any>(url, body).subscribe(
      (res) => {
        console.log(res);
      },
      (error) => {
        if (error.includes('Error Status: 200')) {
          this.setErpSplash(erpConnectValue);
          sessionStorage.setItem('ack', JSON.stringify(mergedAcknowledgements));
        }
        console.log(error);
      });
  }

  saveAcctVerfSplashInfo(accountVerfSplashValue) {
    const url = environment.univ_saveUserProfileJsonData;
    const ack = sessionStorage.getItem('ack');
    let mergedAck;
      if (ack) {
        mergedAck = JSON.parse(ack)
      }

    const mergedAcknowledgements = { ...mergedAck, accountVerfSplash: accountVerfSplashValue };

    const body = {
      universalId: this.universalId,
      acknowledgements: mergedAcknowledgements
    };

    this.https.post<any>(url, body).subscribe(
      (res) => {
        console.log(res);
      },
      (error) => {
        if (error.includes('Error Status: 200')) {
          this.setAcctVerfSplash(accountVerfSplashValue);
          sessionStorage.setItem('ack', JSON.stringify(mergedAcknowledgements));
        }
        console.log(error);
      });
  }

  saveFdxApiSplashInfo(fdxApiSplashValue) {
    const url = environment.univ_saveUserProfileJsonData;
    const ack = sessionStorage.getItem('ack');
    let mergedAck;
    if (ack) {
      mergedAck = JSON.parse(ack)
    }

    const mergedAcknowledgements = { ...mergedAck, fdxApiSplash: fdxApiSplashValue };

    const body = {
      universalId: this.universalId,
      acknowledgements: mergedAcknowledgements
    };

    this.https.post<any>(url, body).subscribe(
      (res) => {
        console.log(res);
      },
      (error) => {
        if (error.includes('Error Status: 200')) {
          this.setFdxApiSplash(fdxApiSplashValue);
          sessionStorage.setItem('ack', JSON.stringify(mergedAcknowledgements));
        }
        console.log(error);
      });
  }

  getFraudData(companyIds) {
    const url = environment.univ_fraud + '/' + companyIds;
    return this.https.get<any>(url);
  }

  getPhoneRegistered() {
    if (this.userInfo && (this.userInfo.mobilePhone !== "" || this.userInfo.homePhone !== "" )) {
      return 'Y';
    } else {
      return 'N';
    }
  }

  getCredData() {
    const serviceData = this.getData();
    const sessionData = sessionStorage.getItem("credData");
    if (serviceData && ((Array.isArray(serviceData)) || (Object.keys(serviceData).length))) {
      return serviceData;
    } else if (sessionData) {
      return JSON.parse(sessionData);
    } else {
      return {};
    }
  }

  getMonitData() {
    let url = environment.monitUserContext;

    if (!this.universalId) {
      this.universalId = sessionStorage.getItem('universalId');
    }

    const userContextData = this.getCredData();

    const body = {
      authId: userContextData.authId,
      universalId: userContextData.universalId,
      legacyIds: userContextData.mappingLegacyIds
    };

    return this.https.post<any>(url, body);
  }


  getNotificationsData(data: any): Observable<any> {
    const url = environment.univ_getNotificationsUrl;
    return this.https.get(url, { headers: data });
  }

  getSplashInfoFromCDN(): Observable<any> {
    const url = environment.splashInfoCDNUrl;
    return this.https.get(url);
  }

  isMobileDevice() {
    const userAgent = navigator.userAgent;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return true;
    }

    if (/windows phone/i.test(userAgent)) {
      return true;
    }

    if (/android/i.test(userAgent)) {
      return true;
    }

    return false;
  }

}
