import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UniversalSessionService } from 'src/app/core/service/universalSession.service';
import { SsoUtils } from 'src/app/shared/Util/sso-utils';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(private sessionService: UniversalSessionService,
              private router: Router,
              private utils: SsoUtils) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const targetUrl = state.url;
      if (!this.sessionService.isSessionAuthenticated()) {
        const URL = this.sessionService.getCancelUrl();
        if (targetUrl.endsWith('cards')) {
          this.utils.redirect(environment.cards_login_url)
        } else {
          this.utils.redirect(URL);
        }
        return false;
      }
      return true;
  }

}
