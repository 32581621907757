import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertService } from 'src/app/service/shared/alert.service';
import { Subscription } from 'rxjs';
import { assetURL, environment } from 'src/environments/environment';


@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnDestroy {
  private subscription: Subscription;
  message: any;

  
  get assetURL() {
    return assetURL;
  }

  constructor( alertService: AlertService) {
    this.subscription = alertService.getMessage().subscribe(message => {
      if (!!message.text) {
        this.message = message;
      } else {
        this.hide();
      }
    });
  }

  hide(){
    this.message = undefined;
  }

  /**
   * unsubscribe on destroy to prevent memory leaks
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
