<div [ngClass]="{'alertArea': (message.type === 'success' || message.type === 'error'), 'new-message': message.type === 'token no longer needed for accessOPTIMA login'}" *ngIf="message">
  <div [ngClass]="{ 'alert': message, 'alert-success': message.type === 'success', 'sso-alert-danger': message.type === 'error', 'alert-message': message.type === 'token no longer needed for accessOPTIMA login'}">
    <div class="alertHeader">{{ message.type }}
      <span class="cbds-c-iconButton cbds-c-iconButton--contained" aria-label="close" (click)="hide()">
      <svg class="cbds-c-icon close-icon" focusable="false">
        <use [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#close'" [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#close'"></use>
      </svg>
    </span>
    </div>
    <span class="alertContent"><div [innerHTML]="message.text"></div></span>
  </div>
</div>
