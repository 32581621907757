<div #viewContainerRef class="parent-container">
  <div class="parent-container d-sm-flex">
    <div class="container">
      <div class="headSection">Update Universal Profile</div>
      <form (ngSubmit)="onSubmit()" [formGroup]="updateForm" [ngClass]="{ 'was-validated': submitted }"
            novalidate>
        <div class="form">
          <div [ngClass]="{ 'has-error': f.submitted  && f.email.errors }" class="form-row email">
            <div class="col-xl-5 col-lg-6 col-md-7">
              <label for="email">Email</label>
              <input #email (keyup)="emailUpdated()" autocomplete="off" class="form-control" formControlName="email"
                     id="email" name="email" required tabindex="2" type="email" value="email">
              <div [ngClass]="{'invalid-feedback': !f.email.invalid}" id="universalid-error-msg">
                <div class="error-message" id="login-form">Valid Email Address is required</div>
              </div>
              <div *ngIf="showEmailOTPError" class="error-message">
                <span>Send OTP code failed. please try again.</span>
              </div>
            </div>
            <div class="verticalCenter">
              <div *ngIf="hasValue(email)">
                <div *ngIf="emailVerified">
                  <svg class="sso-alert__checkmark" focusable="false">
                    <use [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'"
                         [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'">
                    </use>
                  </svg>
                  <span class="verify-text">Verified</span>
                </div>
                <div *ngIf="!emailVerified">
                  <img class="sso-alert_blank" src="{{assetURL}}assets/icons/gray-circle.svg"/>
                  <a (click)="openTest('EMAIL')" cbdata-reason="update-profile-test-email" cbdata-type="link"
                     class="test-phone cbensightenevent">Test Email</a>
                </div>
              </div>
            </div>
          </div>

          <!-- Mobile Phone -->
          <div [ngClass]="{ 'has-error': f.submitted  && f.mobile.errors }" class="form-row">
            <div class="col-md-auto col-sm-auto">
              <label for="mobile">Mobile Phone</label>
              <div ngbDropdown>
                <button class="toggleBtn widthAuto" id="countryDropdown1" ngbDropdownToggle type="button">
                  <span [class]="'fi fi-'+ selectedItem?.countryCode"></span> &nbsp; {{selectedItem?.country}}
                </button>
                <div aria-labelledby="countryDropdown1" ngbDropdownMenu>
                  <button (click)="selectedCountry(country, true)" *ngFor="let country of countries"
                          ngbDropdownItem type="button" value={{country.countryCode}}>
                    <span [class]="'fi fi-'+ country.countryCode + ' fis'"></span> &nbsp; {{country.country}}
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-auto col-sm-auto fakeLabel">
              <input #mobile (keyup)="mobileUpdated()" [attr.maxlength]="isMobileCountryUS() ? 10 : 20" appNumbersOnly
                     autocomplete="off" class="form-control widthAuto"
                     formControlName="mobile" id="mobile" inputmode="numeric" name="mobile" tabindex="4"
                     type="tel">
            </div>
            <div class="verticalCenterPhone">
              <div *ngIf="hasValue(mobile)">
                <div *ngIf="mobileVerified">
                  <svg class="sso-alert__checkmark" focusable="false">
                    <use [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'"
                         [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'">
                    </use>
                  </svg>
                  <span class="verify-text">Verified</span>
                </div>
                <div *ngIf="!mobileVerified">
                  <img class="sso-alert_blank phone-test" src="{{assetURL}}assets/icons/gray-circle.svg"/>
                  <a (click)="openTest('SMS')" cbdata-reason="update-profile-test-sms" cbdata-type="link"
                     class="test-phone cbensightenevent">Test Phone</a>
                </div>
              </div>
            </div>
            <div *ngIf="showMobileOTPError" class="error-message">
              <span>Send OTP code failed. please try again.</span>
            </div>
          </div>

          <!-- Landline Phone -->
          <div [ngClass]="{ 'has-error': f.submitted  && f.landline.errors }" class="form-row">
            <div class="col-md-auto col-sm-auto">
              <label for="landline">Landline Phone</label>
              <div ngbDropdown>
                <button class="toggleBtn widthAuto" id="countryDropdown2" ngbDropdownToggle type="button">
                  <span [class]="'fi fi-'+ selectedLandline?.countryCode"></span>
                  &nbsp; {{selectedLandline?.country}}</button>
                <div aria-labelledby="countryDropdown2" ngbDropdownMenu>
                  <button (click)="selectedCountry(country, false)" *ngFor="let country of countries"
                          ngbDropdownItem type="button" value={{country.countryCode}}>
                    <span [class]="'fi fi-'+ country.countryCode + ' fis'"></span> &nbsp; {{country.country}}
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-auto col-sm-auto fakeLabel">
              <input #landline (keyup)="landlineUpdated()" [attr.maxlength]="isLandlineCountryUS() ? 10 : 20"
                     appNumbersOnly autocomplete="off" class="form-control widthAuto"
                     formControlName="landline" id="landline" inputmode="numeric" name="landline" tabindex="4"
                     type="tel">
            </div>
            <div class="verticalCenterPhone">
              <div *ngIf="hasValue(landline)">
                <div *ngIf="landlineVerified">
                  <svg class="sso-alert__checkmark" focusable="false">
                    <use [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'"
                        [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'">
                    </use>
                  </svg>
                  <span class="verify-text">Verified</span>
                </div>
                <div *ngIf="!landlineVerified">
                  <img class="sso-alert_blank phone-test" src="{{assetURL}}assets/icons/gray-circle.svg"/>
                  <a (click)="openTest('VOICE')" cbdata-reason="update-profile-test-voice" cbdata-type="link"
                    class="test-phone cbensightenevent">Test Phone</a>
                </div>
              </div>
            </div>
            <div *ngIf="showLandlineOTPError" class="error-message">
              <span>Send OTP code failed. please try again.</span>
            </div>
          </div>

          <!-- Country of Residence -->
          <div [ngClass]="{ 'has-error': f.submitted  && f.countryOfResidence.errors }" class="form-row">
            <div class="col-md-auto col-sm-auto">
              <label for="countryOfResidence">Country of Residence</label>
              <div ngbDropdown>
                <button class="toggleBtn button-country" id="countryDropdown3" ngbDropdownToggle type="button">
                  <span [class]="'country-left-span fi fi-'+ selectedCountryOfResidence?.countryCode"></span>&nbsp; 
                  <span class="country-left-span" [ngClass]="{'default-value': !selectedCountryOfResidence?.country}">
                    {{selectedCountryOfResidence?.country ? selectedCountryOfResidence?.country : 'Select one'}}</span>
                  <span class="caret-country"></span>
                </button>
                <div aria-labelledby="country-of-Residence" ngbDropdownMenu>
                  <button (click)="getCountryOfResidence(country)" *ngFor="let country of countries"
                          ngbDropdownItem type="button" value={{country.countryCode}}>
                    <span [class]="'fi fi-'+ country.countryCode + ' fis'"></span> &nbsp; {{country.country}}
                  </button>
                </div>
              </div>
              <div [ngClass]="{'invalid-feedback': !f.countryOfResidence.invalid}" id="universalid-error-msg">
                <div class="error-message" id="login-form">Country of Residence is required</div>
              </div>
            </div> 
          </div>

          <!-- Year of Birth -->
          <div [ngClass]="{ 'has-error': f.submitted  && f.yearOfBirth.errors }" class="form-row">
            <div class="col-md-auto col-sm-auto">
              <label for="countryOfResidence">Year of Birth</label>
              <div ngbDropdown>
                 <button class="toggleBtn button-country" id="countryDropdown3" ngbDropdownToggle type="button" style="height: 37px;width: 160px;"> 
                  <span class="country-left-span" [ngStyle]="{'color': !selectedYearOfBirth ? '#8e8e8f': ''}">
                    {{selectedYearOfBirth ? selectedYearOfBirth : 'Select one'}}</span>
                  <span class="caret-country"></span>
                </button>
                <div aria-labelledby="year-of-birth" ngbDropdownMenu>
                  <button (click)="getYearOfBirth(year)" *ngFor="let year of years"
                          ngbDropdownItem type="button" value={{year}}>
                    {{year}}
                  </button>
                </div>
              </div>
              <div [ngClass]="{'invalid-feedback': !f.yearOfBirth.invalid}" id="universalid-error-msg">
                <div class="error-message" id="login-form">Year of Birth is required</div>
              </div>
            </div>
          </div>

          <!-- Full Name -->
          <div class="form-row">
            <div class="col-7">
              <label for="fullName">Full Name</label>
              <span class="sso-info-tooltip">
                <svg class="sso-info" focusable="false">
                  <use [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#error-circle'"
                       [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#error-circle'">
                  </use>
                </svg>
                <span class="tooltip-text info-tooltip"><span class="hover-text">Contact Client Services at 877-550-5933
                    or email us at
                    clientServices&#64;citizensbank.com to update Full Name
                    <span id="tooltip-tail"></span>
                  </span></span>
              </span>
              <span class="first-name" id="fullName">{{fullName}}</span>
            </div>
          </div>

          <div class="buttonArea">
            <button #btnLogin [disabled]="loading || updateProfileCheck" aria-label="Next" cbdata-reason="update-profile-save"
                    cbdata-type="button"
                    class="btn btn-primary cbensightenevent" id="btnLogin" name="btnLogin" tabindex="4"
                    type="submit"
                    value="Save">Save
            </button>
            <button (click)="onCancel($event)" aria-label="Cancel" cbdata-reason="update-profile-cancel"
                    cbdata-type="button"
                    class="btn btn-secondary cbensightenevent" id="btnCancel" name="btnBack" tabindex="5"
                    value="Cancel">Cancel
            </button>
          </div>
        </div>
      </form>
    </div>

  </div>

  <div class="need-assistance">Important</div>
  <div class="contactArea">
    Any changes you make to your email address or phone number(s) will only apply to One-Time Code delivery.  
    If you need to change your contact information for alerts/notifications of a specific application, 
    please navigate to the application for contact updates.
  </div>
</div>

<!-- Test Dialog -->
<div [ngStyle]="{'display':displayStyle}" class="modal test-modal" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div *ngIf="showPopupAlertMessage" class="popup-alert-message">
        <div class="alertArea">
          <div class="sso-alert-danger">
            <div class="alertHeader">error
              <span (click)="hideAlertArea()" aria-label="close" class="cbds-c-iconButton cbds-c-iconButton--contained">
              <svg class="cbds-c-icon close-icon" focusable="false">
                <use [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#close'"
                     [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#close'"></use>
              </svg>
            </span>
            </div>
            <span class="alertContent"><div [innerHTML]="alertmessage"></div></span>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="modal-header headSection">
          Test Your Information
          <span (click)="onCancelTest(passcodeForm.value)" aria-label="close"
                class="cbds-c-iconButton remove-popup cbds-c-iconButton--contained">
          <svg class="cbds-c-icon close-icon" focusable="false">
            <use [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#close'" [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#close'">
            </use>
          </svg>
        </span>
        </div>
        <div class="modal-body modal-dialog-scrollable">
          <form (ngSubmit)="onSubmitPasscode(passcodeForm.value)" [formGroup]="passcodeForm"
                [ngClass]="{ 'was-validated': submitted }" novalidate>
            <div class="form">
              <div class="directions">Your test OTP has been sent to {{ otpTarget }}. Please retrieve it and enter
                below.</div>
              <div [ngClass]="{ 'has-error': fPasscode.invalid  && fPasscode.passcode.errors }" class="form-row">
                <div>
                  <label for="passcode">Enter One-Time Passcode</label>
                  <input #passcode (keyup)="hideOTPErrors()" appNumbersOnly autocomplete="off"
                         class="form-control passcode-text" formControlName="passcode" id="passcode" inputmode="numeric"
                         maxlength="6" name="passcode" pattern="[0-9]*"
                         required tabindex="0" type="text">
                  <div *ngIf="otpRequired">
                    <div class="error-message">One-Time Passcode is required</div>
                  </div>
                </div>
              </div>
              <div *ngIf="verifyOTPError" class="error-message">
                <span>Verify OTP code failed. please try again.</span>
              </div>
              <div *ngIf="resendOTPError" class="error-message">
                <span>Resend code failed, please try again.</span>
              </div>
              <div *ngIf="displayOTPErrorMessage" class="form-row error-message">
                The One-Time Passcode (OTP) you entered is incorrect or has expired.
                <span>Please check the OTP and try again or request another code.</span>
              </div>

              <div class="custom-control-text last-row">
              <span>The code expires in 15 minutes.<br>
                Didn't get the code? <span style="color: rgb(217, 61, 0);">Please wait a few minutes before clicking Resend Code.  Some email and text providers may take up to 1 - 2 minutes to deliver your passcode. </span><br>
                
                <a (click)="getCode()" cbdata-reason="update-profile-otp-resend"
                                        cbdata-type="link"
                                        class="cbensightenevent" href="javascript:void(0);"
                                        tabindex="1">Resend Code</a></span>
              </div>

              <div class="buttonArea">
                <button #btnContinue (click)="verifyOTP()" [disabled]="loading" aria-label="Verify"
                        cbdata-reason="update-profile-otp-verify" cbdata-type="button"
                        class="btn btn-primary cbensightenevent" id="btnContinue" name="btnContinue" tabindex="3"
                        type="submit"
                        value="Verify">Verify
                </button>
                <button (click)="onCancelTest(passcodeForm.value)" aria-label="Cancel"
                        cbdata-reason="update-profile-otp-cancel"
                        cbdata-type="button"
                        class="btn btn-secondary cbensightenevent" id="btnBack" name="btnBack"
                        value="Cancel">Cancel
                </button>
              </div>
            </div>
            <div class="infoArea">
              If you do not receive your test one time passcode, please try a different email address or phone number.
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
